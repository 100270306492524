import React from "react"
import Layout from "../components/layout"
import { Helmet } from 'react-helmet';

const SecurityPage = () => (
    <Layout>
        <Helmet>
            <title>Managers App Security</title>
            <meta name="description" content="At Managers App we take the security and integrity of your data very seriously"></meta>
        </Helmet>
        <div className={"container"}>
            <div className={"content"}>
                <div className={"title"}>
                    <h1>Security</h1>
                </div>
                <p>
                    At ManagersApp we take the security and integrity of your data very seriously. 
                    We have put controls in place to make sure your data is safe.
                </p>

                <h2>Product security</h2>
                <p>
                    Authenticaion -  ManagersApp uses Google Cloud Authentication and does not store password data on its servers.
                </p>

                <h2>Network and application security</h2>
                <p>
                    Data hosting and storage - Our servers are hosted on Google Cloud Platform. Access to these servers is restricted to ManagersApp's team members who need it to perform their jobs. 
                    More information about Google's security policy can be <a href="https://firebase.google.com/support/privacy" target="_blank" rel="noreferrer">found here.</a>
                </p>
                <p>
                    Website - All data transmitted from your browser to ManagersApp's servers is encrypted with SSL.
                </p>
                <p>
                    Backups - Our data is backed up daily to a separate data center to ensure safety.
                </p>

                <h2>Other Areas</h2>
                <p>Payments - All payments go through our payment processor, Stripe. Details about their security setup and PCI compliance can be found at Stripe's  
                    <a href="https://stripe.com/docs/security" target="_blank" rel="noreferrer"> security page.</a>
                </p>

            </div>
        </div>
    </Layout>
)

export default SecurityPage
